import React from "react";
import { styled } from "@mui/material/styles";

// Customizable Area Start
import {
    Typography,
    Box,
    IconButton,
    InputBase,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
    Tab,
    InputAdornment,
    Menu,
    MenuItem,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Button,
    Grid,
    Radio,
} from "@material-ui/core";
import { sortingIcon, img_, seacrhIcon, filterIcon, EditImg, Delete_Img } from "../../user-profile-basic/src/assets";

import MyProductsController, {
    Props,
} from "./MyProductsController";
import SideBar from "../../../components/src/SideBar.web"
import Usernavbar from "../../../components/src/UserNavbar.web";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination, PaginationItem } from "@mui/material";
import { productimg } from "./assets";


// Customizable Area End

export default class MyProducts extends MyProductsController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    /*istanbul ignore next*/
    MyProducts = () => {

        console.log("hello", this.state.ProductData)
        const tabData = [
            { label: 'All Results', value: 0 },
        ];

        const brandkeys = Object.keys(this.state.selectedBrands);
        const half = Math.ceil(brandkeys.length / 2);
        const leftBrands = brandkeys.slice(0, half);
        const rightBrands = brandkeys.slice(half);


        return (
            <Box style={{ width: "94%", ...webStyle.MainContainer }}>
                <Box style={webStyle.Headers}>
                    <Typography variant="h6" style={webStyle.Headingss}>
                        My Products
                    </Typography>
                </Box>
                <Box style={{ border: "1px solid #E2E7F0", borderRadius: "8px" }}>
                    <Box style={{ ...webStyle.NavTab }}>
                        <Box
                            style={{
                                flexGrow: 1,
                                display: "flex",
                                overflowX: "auto",
                            }}
                        >
                            <Tabs value={0} TabIndicatorProps={{
                                style: { background: "#EE6900", height: "4px", borderRadius: "8px", },
                            }}>
                                {tabData.map(({ label, value }) => (
                                    <Tab
                                        key={value}
                                        style={{ height: "58px", textTransform: "none", }}
                                        label={
                                            <Typography style={webStyle.ActiveTabs}>
                                                {label}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        <Box style={webStyle.SearchContainers}>
                            <InputBase
                                placeholder="Search"
                                style={webStyle.SearchInputs}
                                inputProps={{
                                    style: {
                                        fontFamily: 'Inter, sans-serif',
                                        fontWeight: 400,
                                        color: "rgba(0, 0, 0, 0.87)",
                                        fontSize: 12,
                                    },
                                }}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <img src={seacrhIcon} alt="search icon" data-testId="Serach-image" style={{ width: 20, height: 20 }} />
                                    </InputAdornment>
                                }
                            />
                            <IconButton>
                                <img src={sortingIcon} onClick={this.handleToggle1} alt="sorting icon" data-testId="MoreVertIcon" style={{ width: 20, height: 20, marginLeft: "8px" }} />
                                {this.state.open1 && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "85%",
                                            right: "0",
                                            width: 300,
                                            bgcolor: "#FFFFFF",
                                            boxShadow: "0px 10px 10px rgba(0, 0, 0, 0.1)",
                                            borderRadius: 10,
                                            p: 0,
                                            zIndex: 5,
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                borderBottom: "1px solid #DEE1E6",
                                                marginBottom: 16,
                                                padding: "15px 20px",
                                            }}
                                        >
                                            <img
                                                src={sortingIcon}
                                                alt="sort icon"
                                                style={{ width: 20, height: 20, marginRight: 8 }}
                                            />
                                            <span style={{ fontSize: "1.25rem", fontWeight: "bold", color: "black" }}>
                                                Sort By
                                            </span>
                                        </div>
                                        <Grid container spacing={2} style={{ padding: "0px 50px 20px 30px" }}>
                                            <Typography style={{ color: "black", fontWeight: "bold", fontSize: "1.15rem", paddingTop: "10px", paddingBottom: "10px" }}>Modified Date & Time </Typography>
                                            <FormGroup>
                                                <FormControlLabel style={{ color: "black" }}
                                                    control={
                                                        <Radio
                                                            checked={this.state.selectedOption === "newest"}
                                                            onChange={() => this.handleRadioChange("newest")}
                                                            value="newest"
                                                            style={{ color: "#FF6600" }}
                                                            data-testid="newest-radio"
                                                        />
                                                    }
                                                    label="Newest to Oldest"
                                                />
                                                <FormControlLabel style={{ color: "black" }}
                                                    control={
                                                        <Radio
                                                            checked={this.state.selectedOption === "oldest"}
                                                            onChange={() => this.handleRadioChange("oldest")}
                                                            value="oldest"
                                                            style={{ color: "#FF6600" }}
                                                            data-testid="oldest-radio"
                                                        />
                                                    }
                                                    label="Oldest to Newest"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Box>
                                )}
                            </IconButton>
                            <IconButton>
                                <img src={filterIcon} onClick={this.handleToggle} alt="filter icon" data-testId="filterIcons" style={{ width: 20, height: 20 }} />
                                {this.state.open && (
                                    <Box
                                        sx={{
                                            position: "absolute",
                                            top: "85%",
                                            right: "95%",
                                            width: 450,
                                            bgcolor: "#FFFFFF",
                                            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                                            p: 0,
                                            borderRadius: 2,
                                            zIndex: 5
                                        }}
                                    >
                                        <div style={{ display: "flex", marginBottom: 16, paddingLeft: "15px", paddingTop: "30px" }}>
                                            <img
                                                src={filterIcon}
                                                alt="filter icon"
                                                data-testId="filterIcons"
                                                style={{ width: 20, height: 20, marginRight: 8 }}
                                            />
                                            <span style={{ fontSize: "1.25rem", fontWeight: "bold", color: "black" }}>FILTER</span>
                                        </div>
                                        <hr style={{ border: 0, borderTop: "1px solid #DEE1E6", marginBottom: 16 }} />
                                        <Grid container spacing={2}>
                                            <Grid item xs={6} style={{ paddingLeft: "30px" }}>
                                                <FormGroup>
                                                    {leftBrands.map((brand) => (
                                                        <FormControlLabel style={{ color: "black" }}
                                                            key={brand}
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.selectedBrands[brand]}
                                                                    onChange={() => this.handleCheckboxChange(brand)}
                                                                    style={{
                                                                        color: "#FF6600",
                                                                    }}
                                                                />
                                                            }
                                                            label={brand}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Grid>
                                            <Grid item xs={6} style={{ paddingRight: "50px", paddingLeft: "15px" }}>
                                                <FormGroup>
                                                    {rightBrands.map((brand) => (
                                                        <FormControlLabel style={{ color: "black" }}
                                                            key={brand}
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.selectedBrands[brand]}
                                                                    onChange={() => this.handleCheckboxChange(brand)}
                                                                    style={{
                                                                        color: "#FF6600",
                                                                    }}
                                                                />
                                                            }
                                                            label={brand}
                                                        />
                                                    ))}
                                                </FormGroup>
                                            </Grid>
                                        </Grid>
                                        <hr style={{ border: 0, borderTop: "1px solid #DEE1E6", marginBottom: 16 }} />
                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 16, padding: "20px" }}>
                                            <Button onClick={this.handleClearAll} style={{ backgroundColor: "#FFF6F0", color: "#FF6600", paddingLeft: "35px", paddingRight: "35px", borderRadius: "15px" }}>
                                                Clear All
                                            </Button>
                                            <Button
                                                onClick={this.handleApply}
                                                variant="contained"
                                                style={{ backgroundColor: "#FF6600", color: "#fff", paddingLeft: "55px", paddingRight: "55px", borderRadius: "15px" }}
                                            >
                                                Apply
                                            </Button>
                                        </div>
                                    </Box>
                                )}
                            </IconButton>
                        </Box>
                    </Box>
                    <TableContainer>
                        <Table aria-label="simple table">
                            <TableHead style={{ ...webStyle.TableHeads }}>
                                <TableRow style={{ borderBottom: "none" }}>
                                    <TableCell style={{ width: "1%", borderBottom: "none" }}></TableCell>
                                    <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                                        Product
                                    </TableCell>
                                    <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                                        Product ID
                                    </TableCell>
                                    <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                                        Manufacturer
                                    </TableCell>
                                    <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                                        Category
                                    </TableCell>
                                    <TableCell align="left" style={{ ...webStyle.TableCellss, borderBottom: "none" }}>
                                        Sub Category
                                    </TableCell>
                                    <TableCell align="left" style={{ borderBottom: "none" }}>
                                        Actions
                                    </TableCell>
                                    <TableCell style={{ width: "1%", borderBottom: "none" }}></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody style={webStyle.TBodys}>
                                <TableRow style={{ height: "30px" }}></TableRow>

                                {this.state.ProductData.map((brand: any, index: number) => (
                                    <TableRow
                                        key={index}
                                        data-testid="Rowclick"
                                        style={{
                                            height: "45px",
                                            backgroundColor: "white",
                                        }}
                                        onClick={() => console.log("Clicked Product ID:", brand.productId)}
                                    >
                                        <TableCell style={{ width: "1%", borderBottom: "none", borderRight: "1px solid #E7E2F0" }}></TableCell>

                                        {/* Product + Size + Make */}
                                        <TableCell style={{ ...webStyle.Cells1, borderTop: "1px solid #E7E2F0" }}>
                                            <div style={{ display: "flex", alignItems: "center" }}>
                                                <div>
                                                    <img
                                                       src={brand.attributes?.primary_image?.url || productimg}
                                                        alt="logo"
                                                        style={{
                                                            height: "50px",
                                                            width: "40px",
                                                            objectFit: "contain",
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ marginLeft: "10px" }}>
                                                    <b>{brand.attributes.brand_product_name}</b>
                                                    <br />
                                                    {brand.attributes.length_in_inch && brand.attributes.width_in_inch && brand.attributes.inlet_size ? (
                                                        <span style={{ color: "#323842", fontFamily: "Inter, sans-serif", fontWeight: "normal" }}>Size: {brand.attributes.length_in_inch}x{brand.attributes.width_in_inch}x{brand.attributes.inlet_size} mm</span>
                                                    ) : (
                                                        <span style={{ color: "#323842", fontWeight: "normal", fontFamily: "Inter, sans-serif" }}>Size: Not Available</span>
                                                    )}
                                                </div>
                                            </div>
                                        </TableCell>

                                        {/* Product ID */}
                                        <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.attributes.id}</TableCell>

                                        {/* Manufacturer */}
                                        <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.manufacturer}</TableCell>

                                        {/* Category */}
                                        <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.attributes.category}</TableCell>

                                        {/* Sub Category */}
                                        <TableCell style={{ ...webStyle.Cells, borderTop: "1px solid #E7E2F0" }}>{brand.attributes.sub_category_id}</TableCell>

                                        {/* Actions */}
                                        <TableCell align="left" style={{ borderTop: "1px solid #E7E2Ef", borderRight: "1px solid #E7E2EF" }}>
                                            <IconButton
                                                aria-label="more"
                                                onClick={(e) => this.handleMenuClick(e)} // Pass product ID if needed
                                                data-testid="MoreIcon"
                                            >
                                                <MoreVertIcon />
                                            </IconButton>

                                            <Menu
                                                anchorEl={this.state.anchorEl}
                                                keepMounted
                                                onClose={this.handleMenuClose}
                                                open={Boolean(this.state.anchorEl)}
                                                PaperProps={{
                                                    style: {
                                                        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
                                                        width: "180px",
                                                        borderRadius: "8px",
                                                        padding: "4px 0",
                                                    },
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                            >

                                                <MenuItem
                                                    onClick={() => this.navigateToProductCatalogue('/Pricing', this.state.selectedProductId)}
                                                    data-testId="EditMenuItem"
                                                    style={{ padding: "8px 16px", fontSize: "14px", color: "#1D2939" }}
                                                >
                                                    <img src={EditImg} alt="editicon" style={{ paddingRight: '10px', height: "16px", width: "16px" }} />
                                                    Edit Pricing
                                                </MenuItem>

                                                <MenuItem
                                                    data-testId="DeleteMenuItem"
                                                    style={{ padding: "8px 16px", fontSize: "14px", color: "#1D2939" }}
                                                >
                                                    <img src={Delete_Img} alt="deleteicon" style={{ paddingRight: '10px', height: "16px", width: "16px" }} />
                                                    Remove Product
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>

                                        <TableCell style={{ width: "1%", borderBottom: "none" }}></TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell style={{ width: "1%", borderBottom: "none", borderRight: "none", }}></TableCell>
                                    <TableCell style={{ borderLeft: "1px solid #E7E2F0", borderTop: "none" }} colSpan={6} >
                                        <Box
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                                padding: "10px 16px",


                                            }}
                                        >
                                            <Typography style={{ fontSize: "12px", fontWeight: "normal", color: "#64748B" }}>
                                                {this.getResultTextApplication(this.state.currentPage)}
                                            </Typography>
                                            <Pagination
                                                onChange={this.handlePageChange}
                                                page={this.state.currentPage}
                                                siblingCount={0}
                                                boundaryCount={1}
                                                shape="rounded"
                                                count={Math.ceil(this.state.totalResults / this.state.resultperPage)}
                                                renderItem={(item) => (
                                                    <PaginationItem
                                                        {...item}
                                                        style={{
                                                            fontSize: "12px",
                                                            fontWeight: 500,
                                                            minWidth: "31px",
                                                            height: "36px",
                                                            borderRadius: "12px",
                                                            fontFamily: "Inter, sans-serif",
                                                            color: item.selected ? "#FFFFFF" : "#475569",
                                                            backgroundColor: item.selected ? "#FF6600" : "#F3F4F6",
                                                            border: "1px solid #E2E8F0",
                                                            boxShadow: item.selected ? "0px 4px 6px rgba(0, 0, 0, 0.1)" : "none",
                                                        }}
                                                    />
                                                )}
                                            />
                                        </Box>
                                    </TableCell>
                                    <TableCell style={{ width: "1%", borderLeft: "1px solid #E7E2F0", borderRight: "none", borderTop: "none", borderBottom: "none" }}></TableCell>
                                </TableRow>
                                <TableRow style={{ height: "30px" }}></TableRow>
                            </TableBody>
                        </Table>


                    </TableContainer>
                </Box>
            </Box>
        );
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                <Usernavbar history={this.props.history} />
                <SideBar mycart={true} navigation={this.props.navigation} data-testId="searchText">
                    {this.MyProducts()}
                </SideBar>
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyle: {
    [key: string]: {
        [property: string]: string | number;
    };
} = {
    MainContainer: {
        marginLeft: "47px",
        fontFamily: "Inter, sans-serif",
        zIndex: 1
    },
    Headers: {
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        alignItems: "center",
        margin: "20px 0px",
    },
    Headingss: {
        fontFamily: "Inter, sans-serif ",
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "left",
        lineHeight: "26px",
    },
    CheckoutButton: {
        backgroundColor: "#ff6600",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "white",
        borderRadius: "13px",
        height: "44px",
        width: "max-content",
    },
    TBodys: {
        padding: "20px",
    },
    Cells1: {
        fontFamily: "Inter, sans-serif",
        fontSize: "14px",
        fontWeight: 700,
        color: "#323842",
    },
    Cells: {
        fontFamily: "Inter, sans-serif ",
        fontSize: "12px",
        fontWeight: 400,
        color: "#323842",
    },
    TableHeads: {
        backgroundColor: "#f3f4f6",
    },
    TableCellss: {
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        backgroundColor: "#F3F4F6",
        textAlign: "left",
        color: "#565E6C",
    },
    NavTab: {
        display: "flex",
        justifyContent: "space-between",
        height: "58px",
        alignItems: "center",
        width: "100%",
    },
    SearchContainers: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "265px",
    },
    SearchInputs: {
        width: "173px",
        height: "35px",
        borderRadius: "9px",
        fontFamily: "Inter, sans-serif",
        padding: "0px 10px",
        backgroundColor: "#F3F4F6",
    },
    inactiveTab: {
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 400,
        color: "#000000",
        lineHeight: "20px",
        textAlign: "left",
    },
    ActiveTabs: {
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        textAlign: "left",
        color: "#EE6900",
        lineHeight: "20px",
    },
};
// Customizable Area End