export const seacrhIcon = require("../assets/search.svg");
export const filterIcon = require("../assets/filter.svg");
export const sortingIcon = require("../assets/sorting.svg");
export const ViewIcon = require("../assets/view.svg");
export const negotiateIcon = require("../assets/negotiate.svg");
export const rejectIcon = require("../assets/reject.svg");
export const acceptIcon = require("../assets/accept.svg");
export const lbow = require("../assets/lbow.svg");
export const View = require("../assets/View.svg");
export const groupBox = require("../assets/group_Box.svg");
export const gropdelivery = require("../assets/group_Delivery.svg");
export const grouptime = require("../assets/group_Time.svg");
export const groupworld = require("../assets/group_World.svg");
export const tick = require("../assets/tick.svg");
export const AddImg = require("../assets/add.svg");
export const productimg= require("../assets/Placeholder.png")










