Object.defineProperty(exports, '__esModule', {
  value: true
})

// Customizable Area Start
exports.apiContentType = 'application/json'
exports.getApiMethod = 'GET'
exports.putApiMethod = 'PUT'
exports.postApiMethod = 'POST'
exports.deleteApiMethod = 'DELETE'

exports.getOrdersApiEndPoint = 'shopping_cart/orders'
exports.createOrderItemApiEndPoint = 'shopping_cart/order_items'
exports.deleteOrderItemApiEndPoint = 'shopping_cart/orders'

exports.errorTitle = 'Error'
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.'
exports.getcartItems ='bx_block_dashboard/carts/get_all_cart_items'
exports.deletemycartItem='bx_block_dashboard/carts/remove_item/'
exports.getQuotationItem = 'bx_block_request_management/quotations'
exports.getComperisionQuotationItem = 'bx_block_request_management/quotations/quotation_comparison'
exports.rejectComperisionQuotationItem = 'bx_block_request_management/quotations/update_quotation_status'
exports.acceptComperisionQuotationItem = 'bx_block_request_management/quotations/update_quotation_item'
exports.catalogue = 'bx_block_catalogue/catalogues'
exports.Rating = 'bx_block_productdescription/ratings'
exports.MyProduct = 'bx_block_productdescription/products/get_all_products'






// Customizable Area End
